<template>
  <div class="row">
    <div class="col">
      <form class="form-horizontal" role="form">
        <b-form-group
          id="example text"
          label-cols-sm="2"
          label-cols-lg="2"
          label="Categoria"
          label-for="text"
        >
          <div class="row">
            <div class="col">
              <b-select value="1">
                <option value="1" selected>One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </b-select>
            </div>
            <div class="col-3 text-right">
              <button
                type="button"
                @click="formCategoria = true"
                class="btn btn-primary btn-block"
              >
                <i class="fa fa-plus"></i> nova categoria
              </button>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          id="example text"
          label-cols-sm="2"
          label-cols-lg="2"
          label="Nome"
          label-for="text"
        >
          <b-form-input for="text" value="João"></b-form-input>
        </b-form-group>

        <b-form-group
          id="example-search"
          label-cols-sm="2"
          label-cols-lg="2"
          label="E-mail"
          label-for="search"
        >
          <b-form-input
            id="search"
            value="teste@teste.com.br"
            type="search"
            name="search"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="example-email"
          label-cols-sm="2"
          label-cols-lg="2"
          label="Documento"
          label-for="email"
        >
          <b-form-input id="email" value="123456789"></b-form-input>
        </b-form-group>

        <b-form-group
          id="example-url"
          label-cols-sm="2"
          label-cols-lg="2"
          label="Nascimento"
          label-for="url"
        >
          <b-form-input
            id="url"
            value="13/12/1993"
            type="url"
            name="url"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="example-tel"
          label-cols-sm="2"
          label-cols-lg="2"
          label="Endereço"
          label-for="tele"
        >
          <b-form-input
            id="tele"
            value="Rua teste, 123"
            type="tel"
            name="tel"
          ></b-form-input>
        </b-form-group>
        <div class="row">
          <div class="col-2 p-2"></div>
          <div class="col-4">
            <button
              type="button"
              @click="$emit('close', item)"
              class="btn btn-block btn-outline-dark"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              @click="saveButton"
              class="btn btn-block btn-success"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-5" v-show="formCategoria">
      <div class="card card-dark">
        <div class="card-body">
          <h2>Nova categoria</h2>

          <form class="form-horizontal" role="form">
            <b-form-group
              id="example text"
              label-cols-sm="2"
              label-cols-lg="2"
              label="Título"
              label-for="text"
            >
              <b-form-input for="text" value="Categoria x"></b-form-input>
            </b-form-group>
            <b-form-group
              id="example text"
              label-cols-sm="2"
              label-cols-lg="2"
              label="Descrião"
              label-for="text"
            >
              <b-form-textarea for="text" value="João"></b-form-textarea>
            </b-form-group>

            <div class="row mt-2">
              <div class="col-2 p-2"></div>
              <div class="col-4">
                <button
                  type="button"
                  @click="formCategoria = false"
                  class="btn btn-block btn-outline-dark"
                >
                  Cancelar
                </button>
              </div>
              <div class="col-6">
                <button
                  type="button"
                  @click="formCategoria = false"
                  class="btn btn-block btn-success"
                >
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      title: "Novo Crud",
      formCategoria: false,
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "FAQs",
          active: true,
        },
      ],
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    target: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    alertText() {
      const newText = "Item criado com sucesso.";
      const editText = "Item editado com sucesso.";
      return this.item && this.item.value ? editText : newText;
    },
  },
  methods: {
    async saveButton() {
      await new Promise((r) => setTimeout(() => r(), 1000));

      Swal.fire({
        title: "Sucesso!",
        text: this.alertText,
        icon: "success",
        target: document.getElementById(this.target),
        confirmButtonText: "OK",
        confirmButtonColor: "#5cb85c",
      }).then(() => {
        this.$emit("close", this.item);
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
